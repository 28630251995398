//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-248:_4916,_8076,_1457,_9012,_3416,_264,_3984,_6784;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-248')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-248', "_4916,_8076,_1457,_9012,_3416,_264,_3984,_6784");
        }
      }catch (ex) {
        console.error(ex);
      }